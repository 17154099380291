import {
  IconOverlay,
  ICON_BLOCK_DEFAULT_CLIP_PATH,
} from '@/components/icon-overlay';
import { Close } from '@/components/icons';
import { CSS, styled } from '@/stitches.config';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import * as Dialog from '@radix-ui/react-dialog';
import { keyframes } from '@stitches/react';

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  css?: CSS;
};

// TODO: make close icon black on mobile screens

export function Modal({ children, open, onClose, css }: ModalProps) {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Overlay>
          <Content
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            onInteractOutside={onClose}
            css={css}
          >
            <Dialog.DialogClose asChild>
              <IconOverlay onClick={onClose}>
                <AccessibleIcon.Root label="Close dialog window">
                  <Close />
                </AccessibleIcon.Root>
                <HoverBlock aria-hidden="true">
                  <DuplicateIconWrapper>
                    <Close
                      style={{ stroke: 'currentcolor', strokeWidth: 0.5 }}
                    />
                  </DuplicateIconWrapper>
                </HoverBlock>
              </IconOverlay>
            </Dialog.DialogClose>
            {children}
          </Content>
        </Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '50%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  '0%': { right: '-100%' },
  '100%': { right: 0 },
});

const slideOut = keyframes({
  '0%': { right: 0 },
  '100%': { right: '-100%' },
});

const Content = styled(Dialog.Content, {
  background: '$background',
  position: 'absolute',
  right: '0',
  height: '100%',
  overflowY: 'auto',
  width: '100%',
  '@bp2': {
    width: '80%',
    maxWidth: 1000,
  },
  // '@bp5': {
  //   maxWidth: 1280,
  // },
  '&[data-state=open]': {
    animation: `${slideIn} 400ms ease-out`,
  },
  '&[data-state=closed]': {
    animation: `${slideOut} 300ms ease-in`,
  },
});

const Overlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  zIndex: 10,
  overflow: 'hidden',
  '&[data-state=open]': {
    animation: `${fadeIn} 1000ms cubic-bezier(0.37, 0, 0.28, 1)`,
  },
  '&[data-state=closed]': {
    animation: `${fadeOut} 1000ms cubic-bezier(0.37, 0, 0.28, 1)`,
  },
});

const HoverBlock = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  left: 0,
  padding: '$2',

  backgroundColor: '$foreground',

  ...ICON_BLOCK_DEFAULT_CLIP_PATH,

  transition: 'clip-path 320ms ease-out',
});

const DuplicateIconWrapper = styled('div', {
  position: 'relative',
  svg: {
    color: '$background',
    stroke: '$background',
  },
});
