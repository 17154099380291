import { Box } from '@/components/box';
import { StyledButton } from '@/components/button-link';
import { Modal } from '@/components/modal';
import { ToolkitIcon } from '@/components/serializers/toolkit-items/icon';
import { Text } from '@/components/text';
import { styled } from '@/stitches.config';
import { SanityResolvedImage } from '@/types';
import { ToolkitItem } from '@/types/sanity';
import { textToArray } from '@/utils/text-to-array';
import NextLink from 'next/link';
import { UnorderedList } from '../serializers/unordered-list';
import { ToolkitCoverImage } from './components/toolkit-cover-image';
import { ArrowRight } from '@/components/icons';

type SubHeadingProps = {
  text: string;
};

function SubHeading({ text }: SubHeadingProps) {
  return (
    <Text
      as="h5"
      size={3}
      css={{
        fontWeight: '$heavy',
        marginBottom: '1em',
      }}
    >
      {text}
    </Text>
  );
}

const IconColumn = styled('div', {
  marginBottom: '1em',
  svg: {
    color: '$background',
  },
  span: {
    color: '$foreground',
  },

  '@bp5': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
});

const Section = styled('div', {
  marginTop: '3em',
});

export interface Toolkit extends Omit<ToolkitItem, 'cover'> {
  cover?: SanityResolvedImage;
  phase?: string;
}

export interface ToolkitModalProps {
  toolkitItem?: Toolkit | null;
  handleClose: () => void;
  isOpen: boolean;
}

export function ToolkitModal({
  toolkitItem,
  handleClose,
  isOpen = false,
}: ToolkitModalProps) {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      {toolkitItem && (
        <>
          <ToolkitCoverImage toolkitCoverImage={toolkitItem?.cover} />

          <Box
            css={{
              paddingTop: '$8',
              paddingBottom: '$9',

              '@bp3': {
                paddingTop: '$10',
              },
            }}
          >
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',

                gap: '$4',
                px: '$8',

                '@bp3': {
                  flexDirection: 'row',
                  pr: '$12',
                  gap: '$8',
                },

                '@bp4': {
                  gap: '$8',
                  px: '$12',
                },
              }}
            >
              <IconColumn
                css={{
                  flex: '125px 0 0',
                }}
              >
                <Box
                  css={{
                    maxWidth: 125,
                  }}
                >
                  <ToolkitIcon item={toolkitItem} />
                </Box>
              </IconColumn>

              <Box>
                <Text
                  as="h4"
                  size={{
                    '@initial': 4,
                    '@bp4': 5,
                  }}
                  css={{
                    mb: '$4',
                    textTransform: 'capitalize',
                  }}
                >
                  {toolkitItem?.phase}
                </Text>

                <Text
                  as="h2"
                  size={11}
                  css={{
                    '@bp4': {
                      maxWidth: '66.67%',
                    },
                  }}
                >
                  {toolkitItem?.name}
                </Text>

                <Text
                  as="p"
                  size={{
                    '@initial': 2,
                    '@bp4': 3,
                  }}
                >
                  {toolkitItem?.description}
                </Text>

                {toolkitItem?.input && (
                  <Section>
                    <SubHeading text="Input Client" />
                    <UnorderedList
                      css={{
                        li: {
                          a: {
                            color: '$foreground',
                            '&:visited': {
                              color: '$gray10',
                            },
                            '&:hover': {
                              color: '$foreground',
                            },
                          },
                        },
                      }}
                    >
                      {textToArray(toolkitItem.input).map((listItem) => (
                        <li key={listItem}>{listItem}</li>
                      ))}
                    </UnorderedList>
                  </Section>
                )}
                {toolkitItem?.deliverables && (
                  <Section>
                    <SubHeading text="Deliverables C°F" />
                    <UnorderedList
                      css={{
                        li: {
                          a: {
                            color: '$foreground',
                            '&:visited': {
                              color: '$gray10',
                            },
                            '&:hover': {
                              color: '$foreground',
                            },
                          },
                        },
                      }}
                    >
                      {textToArray(toolkitItem?.deliverables).map(
                        (listItem) => (
                          <li key={listItem}>{listItem}</li>
                        )
                      )}
                    </UnorderedList>
                  </Section>
                )}
                <Box css={{ margin: '$8 0' }}>
                  <NextLink href="/services#process" passHref scroll={false}>
                    <StyledButton as="a">
                      <span style={{ marginRight: '10px' }}>
                        Go to toolkit overview
                      </span>
                      <ArrowRight />
                    </StyledButton>
                  </NextLink>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Modal>
  );
}
